import React, { useMemo, memo } from 'react'
import clsx from 'clsx'

// Imports => Constants
import { KEYS, SIZES, THEMES } from '@constants'

// Imports => Utilities
import { AcUUID } from '@utils'

// Imports => Atoms
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web'

const _CLASSES = {
  MAIN: 'ac-speech-bubble',
  VISIBLE: 'ac-speech-bubble--visible',
  EMPHASIZED: 'ac-speech-bubble--emphasized',
  CONTENT: 'ac-speech-bubble__content',
}

const AcSpeechBubble = ({ id = AcUUID(), content, visible = true, emphasized = false, children, className }) => {
  const getContentClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT)
  }, [content])

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, visible && _CLASSES.VISIBLE, emphasized && _CLASSES.EMPHASIZED, className)
  }, [visible, emphasized, className])

  return (
    <article className={getMainClassNames}>
      <div className={getContentClassNames}>
        <AcRichContent content={content} />
        {children && <div className={`ac-speech-bubble__extras`}>{children}</div>}
      </div>
    </article>
  )
}

export default memo(AcSpeechBubble)
