import React, { useEffect, useCallback, useMemo, memo } from 'react'
import { Fade } from 'react-awesome-reveal'
import clsx from 'clsx'

// Imports => Constants
import { VISUALS } from '@constants'

// Imports => Utilities
import { AcIsSet } from '@utils'

// Imports => Atoms
import AcHeading from '@atoms/ac-heading/ac-heading.web'
import AcLogo from '@atoms/ac-logo/ac-logo.web'

const _CLASSES = {
	MAIN: 'ac-header',
	TRANSPARENT: 'ac-header--transparent',
	BAR: {
		MAIN: 'ac-header-progress__bar',
		TRACKER: 'ac-header-progress__tracker',
		COUNTER: 'ac-header-progress__counter',
	},
}

const AcHeader = ({
	children,
	logo = false,
	logo_visual = VISUALS.LOGO,
	progress = false,
	closeable = false,
	title = false,
	transparent = false,
}) => {
	const getProgressCounterClassNames = useMemo(() => {
		return clsx(_CLASSES.BAR.COUNTER)
	}, [])

	const getProgressTrackerClassNames = useMemo(() => {
		return clsx(_CLASSES.BAR.TRACKER)
	}, [])

	const getProgressBarClassNames = useMemo(() => {
		return clsx(_CLASSES.BAR.MAIN)
	}, [])

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, transparent && _CLASSES.TRANSPARENT)
	}, [transparent])

	const calculatedProgress = useMemo(() => {
		if (!progress) return 0

		const { value, total } = progress

		let proc = (value / total) * 100
		proc = proc > 100 ? 100 : proc < 0 ? 0 : proc
		proc = proc > 0 ? proc / 100 : proc

		return proc
	}, [progress])

	const renderProgressCounter = useMemo(() => {
		if (!progress) return null

		const { value, total } = progress

		return (
			<div className={getProgressCounterClassNames}>
				<strong>
					<Fade appear spy={value}>
						{value}
					</Fade>
				</strong>
				/{total}
			</div>
		)
	}, [progress])

	const renderProgressBar = useMemo(() => {
		if (!AcIsSet(progress) || progress === false) return null

		const pos = { transform: `scaleX(${calculatedProgress})` }

		return (
			<div className={getProgressBarClassNames}>
				<div className={getProgressTrackerClassNames} style={pos} />
			</div>
		)
	}, [calculatedProgress])

	const renderLogo = useMemo(() => {
		if (!logo) return null
		if (!logo_visual) return null

		return <AcLogo visual={logo_visual} />
	}, [logo, logo_visual])

	const renderTitle = useMemo(() => {
		if (!title) return null

		return <AcHeading rank={7}>{title}</AcHeading>
	}, [title])

	const renderCloseButton = useMemo(() => {
		if (!closeable) return null
	}, [closeable])

	return (
		<header className={getMainClassNames} id={'ac-header'}>
			<div>
				{renderLogo}
				{renderProgressCounter}
				{renderTitle}
				{renderCloseButton}
			</div>
			{renderProgressBar}
		</header>
	)
}

export default memo(AcHeader)
