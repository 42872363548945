import React, { useEffect, useMemo, memo } from 'react'
import { withStore } from '@stores'
import { observer } from 'mobx-react-lite'

import clsx from 'clsx'

// Imports => Constants
import { ICONS, KEYS, ROUTES, THEMES, TYPES, VARIANTS, VISUALS } from '@constants'

// Imports => Utilties
import { AcIsSet, AcIsEmptyString } from '@utils'

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid'
import AcCard from '@atoms/ac-card/ac-card.web'
import AcImage from '@atoms/ac-image/ac-image.web'
import AcButton from '@atoms/ac-button/ac-button.web'
import AcIcon from '@atoms/ac-icon/ac-icon.web'

const _CLASSES = {
  MAIN: 'ac-product-card',
  SIMPLE: 'ac-product-card--simple',
  CONTAINER: 'ac-product-card__container',
  NAME: 'ac-product-card__name',
  IMAGE: {
    CONTAINER: 'ac-product-card__image-container',
    WITH_USP: 'ac-product-card__image-container__with-usp',
    MAIN: 'ac-product-card__image',
    WRP: 'ac-product-card__image-wrp',
  },
}

const AcProductCard = ({ store: { flow }, data, simple = false, gTMClick }) => {
  useEffect(() => {}, [])

  const getProductNameClassNames = useMemo(() => {
    return clsx(_CLASSES.NAME)
  }, [])

  const getProductImageClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.MAIN)
  }, [])

  const getProductImageWithUspClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.WITH_USP)
  }, [])

  const getProductImageContainerClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.CONTAINER)
  }, [])

  const renderProductName = useMemo(() => {
    if (!AcIsSet(data)) return null

    const { name, manufacturer } = data

    let formatted = []
    let Logo = null

    if (simple && manufacturer) {
      formatted.push(manufacturer)
    } else if (manufacturer) {
      let key = manufacturer.replace(/[\W+_]/g, '')
      Logo = VISUALS.BRANDING.SHOE_CARDS.SMALL[`${key.toUpperCase()}_SMALL`]
    }
    formatted.push(name)

    return (
      <div className={getProductNameClassNames}>
        {Logo && <Logo alt={`${manufacturer} logo`} />}
        <span
          dangerouslySetInnerHTML={{
            __html: formatted.join(' '),
          }}
        />
      </div>
    )
  }, [data, simple])

  const renderProductImage = useMemo(() => {
    if (!AcIsSet(data)) return null

    const { usp, img } = data

    return (
      <div className={simple ? getProductImageContainerClassNames : getProductImageWithUspClassNames}>
        <AcImage source={img} className={getProductImageClassNames} />
        {!simple && AcIsSet(usp) && !AcIsEmptyString(usp) && (
          <span
            className={'ac-product-card__usp'}
            dangerouslySetInnerHTML={{
              __html: usp,
            }}
          />
        )}
      </div>
    )
  }, [data])

  const renderPrice = useMemo(() => {
    if (!AcIsSet(data) || !AcIsSet(data.price)) return null

    const { price, price_including_vat } = data

    const excluding = `&euro; ${price.toFixed(2).toString().replace('.', ',')}`
    const including = `&euro; ${price_including_vat.toFixed(2).toString().replace('.', ',')} incl. btw`

    return (
      <div className={'ac-product-card__price'}>
        <span
          dangerouslySetInnerHTML={{
            __html: excluding,
          }}
        />
        <small
          dangerouslySetInnerHTML={{
            __html: including,
          }}
        />
      </div>
    )
  }, [data])

  const renderProperties = useMemo(() => {
    if (!AcIsSet(data) || !AcIsSet(data.properties)) return null

    const collection = data.properties.sort((a, b) => (a > b ? -1 : a < b ? 1 : 0))
    const len = collection.length
    let n = 0
    let result = []

    for (n; n < len; n++) {
      const item = collection[n]

      const object = (
        <li
          key={`ac-product-card-property-${data.id}-${n}`}
          dangerouslySetInnerHTML={{
            __html: item,
          }}
        />
      )

      result.push(object)
    }

    return (
      <div className={'ac-product-card__properties'}>
        <ul>{result}</ul>
      </div>
    )
  }, [data])

  const getProductButtonOptions = useMemo(() => {
    return {
      tag: 'a',
      href: data.url,
      target: '_blank',
      theme: THEMES.PITCH,
      variant: !simple ? VARIANTS.DEFAULT : VARIANTS.TRANSPARENT,
      block: true,
    }
  }, [simple])

  const getContainerClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTAINER)
  }, [])

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, simple && _CLASSES.SIMPLE)
  }, [simple])

  const handleCardClick = (event) => {
    if (event?.preventDefault) event?.preventDefault()
    const win = window.open(`${data.url}?${KEYS.SQUEEZLY_QUERY_PARAM}=${flow.squeezelyId}`, '_blank')
    win.focus()
  }

  const renderSchemaData = useMemo(() => {
    if (!AcIsSet(data?.name)) return null
    const validDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1))

    /*
      classifications: [{feature: "code productklasse internationaal", value: "schoeisel"},…]
      id: 172
      img: "https://allshoes.s3.eu-central-003.backblazeb2.com/f319c7f7-2feb-47a9-9a00-6dd2397cde00-0032326-1000.webp"
      manufacturer: "Redbrick"
      name: "Pulse Speed Lace High S3"
      price: 103.72
      price_including_vat: 125.5
      properties: ["ESD", "Metaalvrije tussenzool", "Uitneembare inlegzool", "Slipvast"]
      sku: "0032326"
      url: "https://api.shoe-selector.staging.acato.nl/api/product-clicks/767a38e4-8d7b-479d-8376-a096bd1f85bd?product=0032326"
      usp: "Snelsluiting"
    */

    let schema = {
      '@context': 'http://schema.org/',
      '@type': 'Product',
      name: data.name,
      image: data.img,
      description: data.properties.join(', '),
      sku: data.sku,
      brand: data.manufacturer,
      offers: {
        '@type': 'Offer',
        priceCurrency: 'EUR',
        price: data.price_including_vat,
        availability: 'http://schema.org/InStock',
        priceValidUntil: validDate,
        seller: {
          '@type': 'Organization',
          name: window.tenant?.name,
        },
        url: data.url,
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        ratingCount: '5',
      },
    }

    return <script type="application/ld+json">{JSON.stringify(schema)}</script>
  }, [data])

  return (
    <AcCard className={getMainClassNames} onClick={handleCardClick}>
      {renderProductName}

      <AcContainer className={getContainerClassNames} fluid>
        <AcRow noGutters>
          <AcColumn>{renderProductImage}</AcColumn>
        </AcRow>

        <AcRow noGutters>
          <AcColumn className={'h-padding-x-16'}>{renderPrice}</AcColumn>
        </AcRow>

        <AcRow noGutters>
          <AcColumn className={'h-padding-x-16'}>{renderProperties}</AcColumn>
        </AcRow>

        <AcRow noGutters>
          <AcColumn>
            <AcButton {...getProductButtonOptions} onClick={handleCardClick}>
              <span>Bekijk schoen</span>
              <AcIcon icon={ICONS.ARROW_RIGHT} />
            </AcButton>
          </AcColumn>
        </AcRow>
      </AcContainer>

      {renderSchemaData}
    </AcCard>
  )
}

export default withStore(observer(AcProductCard))
