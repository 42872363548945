import React, { useRef, useEffect, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { SIZES, THEMES, TYPES, VISUALS } from '@constants';

// Imports => Utilities
import { AcUUID, AcSliderInputInstance } from '@utils';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-slider-input',
	INPUT: 'ac-slider-input__field',
};

let SliderInstance = null;

const AcSliderInput = ({
	id = AcUUID(),
	min = 0,
	max = 300,
	value = 200,
	step = 10,
	callback = () => {},
}) => {
	const $element = useRef(null);
	const $input = useRef(null);

	useEffect(() => {}, [$element, $input]);

	const renderMaxAmount = useMemo(() => {
		return `Maximaal € ${value}`;
	}, [value]);

	const getInputClassNames = useMemo(() => {
		return clsx(_CLASSES.INPUT);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const handleCallback = (event) => {
		const { value } = event.target;
		if (callback) callback(value);
	};

	return (
		<div className={getMainClassNames} ref={$element}>
			<label htmlFor={'ac-slider-input-${id}'}>{renderMaxAmount}</label>
			<input
				type={TYPES.RANGE}
				name={'ac-slider-input-${id}'}
				defaultValue={value}
				min={min}
				max={max}
				step={step}
				ref={$input}
				onChange={handleCallback}
				className={getInputClassNames}
			/>
		</div>
	);
};

export default memo(AcSliderInput);
