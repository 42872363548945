import React, { useCallback } from 'react'
import { ICONS, THEMES, TYPES } from '@constants'
import AcButton from '../ac-button/ac-button.web'
import AcIcon from '../ac-icon/ac-icon.web'
import { withStore } from '../../stores'

export const AcCopyBox = withStore(({ store: { toasters }, code }) => {
  const copyDiscountCode = useCallback(() => {
    window.navigator.clipboard.writeText(code).then(() =>
      toasters
        .add({
          variant: 'success',
          title: 'Kortingscode',
          description: 'Je kortingscode is gekopieerd naar het klembord.',
          closeable: true,
        })
        .catch(() => {
          toasters.add({
            variant: 'error',
            title: 'Kortingscode',
            description: 'Fout bij schrijven naar het klembord.',
            closeable: true,
          })
        }),
    )
  }, [code])

  const copyButtonOpts = {
    type: TYPES.SUBMIT,
    theme: THEMES.DARK,
    title: `Kopieer code`,
    variant: 'light',
    callback: copyDiscountCode,
  }

  return (
    <div className="h-flex-v-align-center ac-copybox">
      <div className="left">{code}</div>
      <div className="right">
        <AcButton {...copyButtonOpts}>
          Kopiëren
          <AcIcon icon={ICONS.CONTENT_COPY} />
        </AcButton>
      </div>
    </div>
  )
})
