// Imports => React
import React, { useEffect, useMemo, memo } from 'react'
import { Fade, AttentionSeeker } from 'react-awesome-reveal'
import clsx from 'clsx'

// Imports => Utilties
import { AcUUID, AcIsSet } from '@utils'

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid'
import AcButton from '@atoms/ac-button/ac-button.web'

const _CLASSES = {
	MAIN: 'ac-drawer',
	HIDDEN: 'ac-drawer--hidden',
	VERTICAL: 'ac-drawer--vertical',
}

let classes = null

const AcDrawer = ({ id, actions, className, ltr = true, visible = false }) => {
	useEffect(() => {}, [])

	const getStyleClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN, !ltr && _CLASSES.VERTICAL, !visible && _CLASSES.HIDDEN, className)
	}, [className, ltr, visible])

	const getPrimarySpy = useMemo(() => {
		return (
			id &&
			AcIsSet(actions) &&
			AcIsSet(actions.primary) &&
			!actions.primary.disabled &&
			`${actions.primary.label} - ${id}`
		)
	}, [actions, id])

	const getSecondarySpy = useMemo(() => {
		return AcIsSet(actions) && AcIsSet(actions.secondary) && !actions.secondary.disabled && actions.secondary.label
	}, [actions])

	const getPrimaryColumnSize = useMemo(() => {
		if (!AcIsSet(actions)) return

		const { secondary, primary } = actions

		return AcIsSet(secondary) && AcIsSet(primary) ? 6 : 12
	}, [actions])

	const getSecondaryColumnSize = useMemo(() => {
		if (!AcIsSet(actions)) return

		const { secondary, primary } = actions

		return AcIsSet(secondary) && AcIsSet(primary) ? 6 : 12
	}, [id, actions])

	const getPrimaryButtonOptions = useMemo(() => {
		if (!AcIsSet(actions)) return
		if (!AcIsSet(actions.primary)) return

		return actions.primary
	}, [id, actions])

	const getSecondaryButtonOptions = useMemo(() => {
		if (!AcIsSet(actions)) return
		if (!AcIsSet(actions.secondary)) return

		return actions.secondary
	}, [id, actions])

	return (
		<section className={getStyleClassNames}>
			{actions && (
				<Fade duration={300} spy={id}>
					<AcContainer key={id}>
						<AcRow>
							<>
								{getSecondaryButtonOptions && (
									<AcColumn xs={getSecondaryColumnSize} sm={getSecondaryColumnSize}>
										<AttentionSeeker effect={'pulse'} duration={300} spy={getSecondarySpy}>
											<AcButton
												{...getSecondaryButtonOptions}
												key={`ac-drawer-action-secondary-${id}`}
												callback={getSecondaryButtonOptions.callback}
												className={'ac-drawer__secondary-button'}
											>
												{getSecondaryButtonOptions.label}
											</AcButton>
										</AttentionSeeker>
									</AcColumn>
								)}
								{getPrimaryButtonOptions && (
									<AcColumn xs={getPrimaryColumnSize} sm={getPrimaryColumnSize}>
										<AttentionSeeker effect={'pulse'} duration={300} spy={getPrimarySpy}>
											<AcButton
												{...getPrimaryButtonOptions}
												key={`ac-drawer-action-primary-${id}`}
												callback={getPrimaryButtonOptions.callback}
												className={'ac-drawer__primary-button'}
											>
												{getPrimaryButtonOptions.label}
											</AcButton>
										</AttentionSeeker>
									</AcColumn>
								)}
							</>
						</AcRow>
					</AcContainer>
				</Fade>
			)}
		</section>
	)
}

export default memo(AcDrawer)
