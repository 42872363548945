// Imports => React
import React, { useState, useEffect, useMemo, memo } from 'react'
import { Fade } from 'react-awesome-reveal'
import clsx from 'clsx'

// Imports => Utilities
import { AcIsSet } from '@utils'

// Imports => Constants
import { ICONS } from '@constants'

// Imports => Atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web'

const _CLASSES = {
  MAIN: 'ac-toaster',
  WAITING: 'ac-toaster--indeterminate',
  PROGRESS: 'ac-toaster__progress',
  INDETERMINATE: 'ac-toaster__indeterminate',
  TYPES: {
    DEFAULT: 'ac-toaster--default',
    INFO: 'ac-toaster--info',
    PENDING: 'ac-toaster--pending',
    UPLOAD: 'ac-toaster--upload-pending',
    DOWNLOAD: 'ac-toaster--download-pending',
    SUCCESS: 'ac-toaster--success',
    WARNING: 'ac-toaster--warning',
    ERROR: 'ac-toaster--error',
  },
  BODY: 'ac-toaster__body',
  ICON: {
    MAIN: 'ac-icon ac-toaster__icon',
    WRP: 'ac-toaster__icon-wrp',
    DEFAULT: 'ac-icon--message-text-outline',
    INFO: 'ac-icon--information-outline',
    SUCCESS: 'ac-icon--checkbox-marked-circle-outline',
    WARNING: 'ac-icon--alert-outline',
    ERROR: 'ac-icon--alert-circle-outline',
  },
  CONTENT: {
    MAIN: 'ac-toaster__content',
    TITLE: 'ac-toaster__title',
    DESCRIPTION: 'ac-toaster__description',
    CODE: 'ac-toaster__code',
  },
  CLOSE: {
    ICON: 'ac-icon ac-icon--close ac-toaster__close-icon',
    WRP: 'ac-toaster__close-icon-wrp',
  },
}

let mounted = false

const AcToaster = ({
  callback,
  closeable = true,
  code,
  delay,
  description,
  expires,
  id,
  title,
  variant,
  indeterminate,
}) => {
  const [timer, setTimer] = useState(null)
  const [state, setState] = useState(true)
  const [close, setClose] = useState(false)

  const handleClose = () => {
    if (!closeable) return
    if (timer) clearTimeout(timer)
    if (callback) callback(id)
    setClose(true)
  }

  const init = () => {
    if (timer) clearTimeout(timer)

    if (!AcIsSet(indeterminate) || !indeterminate) {
      startCountdown()
    }
  }

  useEffect(() => {
    mounted = true
    init()

    return () => {
      mounted = false
      if (timer) clearTimeout(timer)
      if (!indeterminate) handleClose()
    }
  }, [indeterminate])

  const startCountdown = () => {
    if (timer) clearTimeout(timer)

    let d = setTimeout(() => {
      const now = new Date().getTime()

      if (now >= expires) {
        handleClose()
      } else {
        startCountdown()
      }
    }, 1000)

    if (mounted) setTimer(d)
  }

  const getCloseIconClassNames = useMemo(() => {
    return clsx(_CLASSES.CLOSE.ICON)
  }, [])

  const getCloseWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.CLOSE.WRP)
  }, [])

  const getCodeClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT.CODE)
  }, [])

  const getDescriptionClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT.DESCRIPTION)
  }, [])

  const getTitleClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT.TITLE)
  }, [])

  const getContentWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.CONTENT.MAIN)
  }, [])

  const getIndeterminateClassNames = useMemo(() => {
    return clsx(_CLASSES.INDETERMINATE)
  }, [])

  const getBodyClassNames = useMemo(() => {
    return clsx(_CLASSES.BODY)
  }, [])

  const getStyleClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, indeterminate && _CLASSES.WAITING, variant && _CLASSES.TYPES[variant.toUpperCase()])
  }, [variant, indeterminate])

  return (
    <>
      {!close && (
        <div className={getStyleClassNames} id={id} onClick={handleClose}>
          <div className={getBodyClassNames}>
            <div className={getContentWrpClassNames}>
              {title && (
                <Fade duration={200} spy={title} appear>
                  <div
                    className={getTitleClassNames}
                    dangerouslySetInnerHTML={{
                      __html: title,
                    }}
                  />
                </Fade>
              )}
              {description && (
                <Fade duration={200} spy={description} appear>
                  <div
                    className={getDescriptionClassNames}
                    dangerouslySetInnerHTML={{
                      __html: description,
                    }}
                  />
                </Fade>
              )}

              {code && (
                <Fade duration={200} spy={code} appear>
                  <div className={getCodeClassNames}>[Error code: {code}]</div>
                </Fade>
              )}
            </div>

            {closeable && (
              <div className={getCloseWrpClassNames} onClick={handleClose}>
                <AcIcon icon={ICONS.CLOSE} className={getCloseIconClassNames} />
              </div>
            )}

            <div className={getIndeterminateClassNames} />
          </div>
        </div>
      )}
    </>
  )
}

export default memo(AcToaster)
