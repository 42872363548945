// Imports => React
import React, { useEffect } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

// Imports => Constants
import { KEYS } from '@constants';

let lastScrollTop = 0;

const AcScrollHOC = ({ store, children }) => {
	const location = useLocation();

	useEffect(() => {
		const $scroller = document.getElementById('ac-scroller');
		if ($scroller && $scroller.scrollTo) {
			$scroller.scrollTo(0, 0);
			window.scrollTo(0, 0);
			lastScrollTop = 0;
		}
	}, [location]);

	return children;
};

export default withStore(observer(AcScrollHOC));
