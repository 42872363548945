// Imports => React
import React, { useCallback, useMemo, memo } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

// Imports => Constants
import { DEFAULT_ROUTE, TITLES, VISUALS } from '@constants'

// Imports => Utilities
import { AcIsString } from '@utils'

const _CLASSES = {
	MAIN: 'ac-logo',
	CONTAINER: {
		MAIN: 'ac-logo__container',
	},
	VISUAL: {
		MAIN: 'ac-logo__visual',
	},
}

// Component
const AcLogo = ({ Visual = VISUALS.LOGO, callback }) => {
	const handleClick = useCallback(
		(event) => {
			if (event && event.persist) event.persist()

			if (callback) {
				if (event && event.preventDefault) event.preventDefault()
				callback(event)
			}
		},
		[callback],
	)

	const renderLogo = useMemo(() => {
		if (AcIsString(Visual)) {
			return <img src={Visual} alt={'logo-visual'} />
		}

		return <Visual alt={'logo-visual'} />
	}, [Visual])

	const getContainerClassNames = useMemo(() => {
		return clsx(_CLASSES.CONTAINER.MAIN)
	}, [])

	const getStyleClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN)
	}, [])

	return (
		<div className={getStyleClassNames}>
			<div className={getContainerClassNames}>{renderLogo}</div>
		</div>
	)
}

export default memo(AcLogo)
