// Imports => React
import React, { memo, useMemo, useRef, useEffect, useState } from 'react'
import Blazy from 'blazy'
import clsx from 'clsx'

// Imports => Constants
import { TYPES, VISUALS } from '@constants'

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils'

const _CLASSES = {
	MAIN: 'ac-image',
	WRP: 'ac-image-wrp',
	LOADED: 'ac-image-wrp--loaded',
	BLAZY: 'b-lazy',
	IMAGE: 'ac-image--image',
	BACKGROUND: 'ac-image--background-image',
	ERROR: 'ac-image__error',
}

const AcImage = ({
	id = `ac-image-${AcUUID()}`,
	placeholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
	source,
	srcset = false,
	type = TYPES.BACKGROUND,
	alt = '',
	size = 'md',
	callback,
	wrpClassName,
	className,
	lazy = true,
	offset = 250,
	placeholderCss = null,
	container = null,
	loadInvisible = false,
	priority = 'low',
	...rest
}) => {
	const [loaded, setLoaded] = useState(false)
	const [error, setError] = useState(false)

	const [src, setSrc] = useState(source)

	const $wrp = useRef(null)
	const $element = useRef(null)

	const fallbackHeroes = {
		sm: VISUALS.HERO_WALLPAPER_SM,
		md: VISUALS.HERO_WALLPAPER_MD,
		lg: VISUALS.HERO_WALLPAPER_LG,
		xl: VISUALS.HERO_WALLPAPER_XL,
	}

	let timer = null
	let delay = 100
	let blazy = null

	useEffect(() => {
		init()
		return () => clearTimeout(timer)
	}, [src])

	const init = () => {
		if (timer) clearTimeout(timer)
		if (!loaded && !error && src) {
			timer = setTimeout(() => {
				if (blazy === null) {
					blazy = new Blazy(getBlazyConfig)
				} else {
					blazy.revalidate()
				}
				delay = 10
			}, delay)
		} else {
			if (AcIsSet(size) && AcIsSet(fallbackHeroes?.[size])) {
				const fallback = fallbackHeroes[size]
				setSrc(fallback)
			}
		}
	}

	const getBlazyConfig = useMemo(() => {
		return {
			selector: `#${id}`,
			container: container || '#ac-scroller',
			offset,
			loadInvisible,
			success: (elem) => {
				if ($element !== null && $element.current !== null) {
					setLoaded(true)
					if (callback) callback(true)
				}
			},
			error: (elem, msg) => {
				if ($element !== null && $element.current !== null) {
					if (AcIsSet(size) && AcIsSet(fallbackHeroes?.[size])) {
						const fallback = fallbackHeroes[size]
						setSrc(fallback)
					} else {
						setError(true)
					}
					if (callback) callback(false)
				}
			},
		}
	}, [id, callback, src, container, loadInvisible])

	const getErrorClassNames = useMemo(() => {
		return clsx(_CLASSES.ERROR)
	}, [])

	const getMainClassNames = useMemo(() => {
		return clsx([_CLASSES.BLAZY, _CLASSES.MAIN, className])
	}, [])

	const getBackgroundStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.BLAZY, _CLASSES.MAIN, _CLASSES.BACKGROUND, className])
	})

	const getWrapperClassNames = useMemo(() => {
		return clsx([_CLASSES.WRP, wrpClassName, loaded && _CLASSES.LOADED, type === TYPES.IMAGE && _CLASSES.IMAGE])
	}, [loaded])

	const renderError = useMemo(() => {
		if (!error) return null

		return <div className={getErrorClassNames} />
	}, [error])

	const renderInlineImage = useMemo(() => {
		return (
			<img
				src={placeholder}
				fetchpriority={priority}
				data-src={src}
				className={getMainClassNames}
				alt={alt}
				ref={$element}
				id={id}
				{...rest}
			/>
		)
	}, [priority, src])

	const renderBackgroundImage = useMemo(() => {
		return (
			<>
				{placeholderCss && <div style={{ ...placeholderCss, opacity: 1 }} className={getBackgroundStyleClassNames} />}
				<div data-src={src} className={getBackgroundStyleClassNames} ref={$element} id={id} {...rest} />
			</>
		)
	}, [src, placeholderCss])

	const renderImage = useMemo(() => {
		if (type === TYPES.BACKGROUND) return renderBackgroundImage
		else if (type === TYPES.IMAGE) return renderInlineImage
	}, [type, renderInlineImage, renderBackgroundImage])

	return (
		<div className={getWrapperClassNames} ref={$wrp}>
			{renderImage}
			{renderError}
		</div>
	)
}

export default memo(AcImage)
