// Imports => React
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withStore } from '@stores'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

// Imports => Constants
import { KEYS, THEMES, TYPES, ICONS, VARIANTS } from '@constants'

// Imports => Utilities
import { AcIsSet } from '@utils'

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid'
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web'
import AcButton from '@atoms/ac-button/ac-button.web'
import AcLoader from '@atoms/ac-loader/ac-loader.web'
import AcTextInput from '../../atoms/ac-text-input/ac-text-input.web'
import { AcIsEmail } from '../../utilities'
import AcCheckbox from '../../atoms/ac-checkbox/ac-checkbox.web'
import AcSpeechBubble from '@atoms/ac-speech-bubble/ac-speech-bubble.web'
import AcAvatar from '../../atoms/ac-avatar/ac-avatar.web'
import AcIconWeb from '../../atoms/ac-icon/ac-icon.web'
import { AcCopyBox } from '../../atoms/ac-copy-box/ac-copy-box'
import { DISCOUNT_AMOUNT, DISCOUNT_CODE } from '../../config'
import AcEmailModalConfirmWeb from './ac-email-modal.confirm.web'

const _CLASSES = {
  MAIN: 'ac-email-modal',
  CONTENT: 'ac-email-modal__content',
}

const AcEmailModal = ({
  store: { ui, tenant, flow },
  content,
  cancel = {
    label: 'Cancel',
    callback: () => {},
  },
  speechBubbleContent,
  isConfirmation = false,
  confirm = {
    label: 'Email',
    callback: () => {},
  },
}) => {
  const [email, setEmail] = useState()
  const [errorMesg, setErrorMesg] = useState('')
  const [bubbleContent, setBubbleContent] = useState(speechBubbleContent || '')
  const [newsChecked, setNewsChecked] = useState(false)

  const { is_loading, sendEmailAndConsent } = flow

  const handleConfirm = useCallback(
    (email, hasNewsChecked) => async (event) => {
      if (event && event.preventDefault) event.preventDefault()
      if (event && event.persist) event.persist()
      if (!AcIsSet(email) || !AcIsEmail(email)) {
        setErrorMesg('Dit is geen geldig e-mailadres.')
        return
      }
      let hasErrors = false
      try {
        await sendEmailAndConsent(email, hasNewsChecked)
      } catch {
        hasErrors = true
      } finally {
        if (hasErrors) return
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
        let title = 'Gelukt!'
        let bubbleText = 'Binnen een paar minuten krijg je de resultaten in je mailbox!'
        if (hasNewsChecked) {
          title = 'kortingscode verstuurd!'
        }
        ui.emailModal({
          actions: [],
          title,
          callback: async () => {
            await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
          },
          closeable: true,
          body: (
            <AcEmailModalConfirmWeb label={'Sluiten'} speechBubbleContent={bubbleText}>
              {hasNewsChecked && (
                <>
                  <AcRow>
                    <AcColumn>
                      <span className="h-margin-y-20 h-text--align-left h-text--size-smaller">
                        Gebruik direct jouw kortingscode
                      </span>
                    </AcColumn>
                  </AcRow>
                  <AcCopyBox code={DISCOUNT_CODE} />
                </>
              )}
            </AcEmailModalConfirmWeb>
          ),
        })
      }
    },
    [],
  )

  const getConfirmButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.PITCH,
      title: confirm.label || 'Save',
      callback: handleConfirm(email, newsChecked),
    }
  }, [confirm, email, newsChecked])

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT])
  }, [])

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN])
  }, [])

  const checkBoxOptions = useMemo(() => {
    return {
      type: TYPES.CHECKBOX,
      name: 'news',
      id: `news-1`,
      value: 'news',
      checked: newsChecked,
      callback: async (event, name, value, type, checked) => {
        setNewsChecked(checked)
      },
      validation: null,
    }
  }, [newsChecked])

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <AcContainer fluid>
          <AcAvatar visual={tenant.tenant?.avatar} lazy={false} xsmall={true} />

          <AcSpeechBubble content={bubbleContent}></AcSpeechBubble>

          <AcRow className={`h-flex-v-align-center gap-20`}>
            <>
              <AcColumn xxs={12} xs={12} sm={12} md={12} className={'h-text--align-left h-flex-v-align-center'}>
                <AcTextInput
                  className
                  error={errorMesg}
                  callback={(evt, name, value) => {
                    setErrorMesg(null)
                    setEmail(value)
                  }}
                  required
                  name={'email'}
                  placeholder="Vul hier je e-mailadres in"
                />
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={12}
                sm={12}
                md={12}
                className={'h-margin-top-35 h-text--align-left h-flex-v-align-center'}
              >
                <AcCheckbox {...checkBoxOptions}>
                  <span
                    className="h-text--size-smalller"
                    dangerouslySetInnerHTML={{
                      __html:
                        'Ja, ik ontvang naast mijn resultaten direct <strong>' +
                        DISCOUNT_AMOUNT +
                        ' euro korting</strong> en meld mij aan voor acties en meer relevante content',
                    }}
                  />
                </AcCheckbox>
              </AcColumn>
            </>

            <AcColumn xxs={12} xs={12} sm={12} md={12} className={`h-margin-top-25 h-text--align-center`}>
              <AcButton {...getConfirmButtonOptions} disabled={!email || is_loading}>
                <span>{(is_loading && 'Bezig met versturen') || confirm.label}</span>
              </AcButton>
            </AcColumn>
          </AcRow>

          <div className="h-margin-y-20 h-text--align-left h-text--size-small">
            Door op 'versturen' te klikken ga je akkoord met het{' '}
            <a href="https://www.allshoes.eu/nl/privacy/" target="blank">
              privacy statement
            </a>
            van Allshoes Safety Footwear
          </div>
        </AcContainer>
      </div>
    </div>
  )
}

export default withStore(observer(AcEmailModal))
