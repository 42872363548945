import React, { useEffect } from 'react'
import clsx from 'clsx'

// Imports => Utilities
import { AcRippleEffect } from '@utils'

const _CLASSES = {
	MAIN: 'ac-ripple',
	SIMPLE: 'ac-ripple--simple',
	LIGHT: 'ac-ripple--light',
	MEDIUM: 'ac-ripple--medium',
	DARK: 'ac-ripple--dark',
	PITCH: 'ac-ripple--pitch',
	ALPHA: 'ac-ripple--alpha',
	OMEGA: 'ac-ripple--omega',
	OMEGA_DARK: 'ac-ripple--omega-dark',
}

const _SIZES = {
	DEFAULT: 5,
	SMALL: 2,
	LARGE: 7.5,
	XLARGE: 10,
}

const AcRipple = ({
	target = false,
	size = 'default',
	theme = 'default',
	simple = false,
	fixed = false,
	event = 'mousedown',
}) => {
	let el = null
	let ripple = null

	useEffect(() => {
		if (el) initRippleEffect()

		return () => {
			if (ripple) ripple.removeEvents()
		}
	}, [el])

	const initRippleEffect = () => {
		const _size = size ? _SIZES[size.toUpperCase()] : 5

		ripple = new AcRippleEffect(el, target, _size, fixed, event)
	}

	const getStyleClassNames = () => {
		return clsx([_CLASSES.MAIN, theme && _CLASSES[theme.toUpperCase()], simple && _CLASSES.SIMPLE])
	}

	return <span ref={(node) => (el = node)} className={getStyleClassNames()} />
}

export default React.memo(AcRipple)
