// Imports => React
import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Utilities
import { AcUUID } from '@utils';

const _CLASSES = {
	MAIN: 'ac-container',
	FLUID: 'ac-container--fluid',
	GROW: 'ac-container--grow',
};

// Component
const AcGridContainer = ({
	id = AcUUID(),
	rel = 'ac-container',
	fluid,
	grow,
	className,
	children,
}) => {
	const getStyleClassNames = useMemo(() => {
		return clsx(
			!fluid && _CLASSES.MAIN,
			fluid && _CLASSES.FLUID,
			grow && _CLASSES.GROW,
			className
		);
	}, [fluid, grow, className]);

	return (
		<section className={getStyleClassNames} id={id} rel={rel}>
			{children}
		</section>
	);
};

export default memo(AcGridContainer);
