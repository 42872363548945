// Imports => React
import React, { useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { THEMES, SIZES } from '@constants';

// Imports => Atoms
import AcRipple from '@atoms/ac-ripple/ac-ripple.web';

const _CLASSES = {
	MAIN: 'ac-icon',
	CALLBACK: 'ac-icon--clickable',
	PREFIX: 'ac-icon--',
};
// Component
const AcIcon = ({ icon, className, callback, ...rest }) => {
	const handleCallback = (event) => {
		if (callback) callback(event);
	};

	const getStyleClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			icon && `${_CLASSES.PREFIX}${icon}`,
			callback && _CLASSES.CALLBACK,
			className
		);
	}, [icon, className, callback]);

	return (
		<i className={getStyleClassNames} onClick={handleCallback} {...rest}>
			{callback && <AcRipple theme={THEMES.PITCH} size={SIZES.SMALL} simple />}
		</i>
	);
};

export default memo(AcIcon);
