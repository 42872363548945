// Imports => React
import React, { useMemo, useState } from 'react'
import { withStore } from '@stores'
import { observer } from 'mobx-react-lite'
import clsx from 'clsx'

// Imports => Constants
import { KEYS, THEMES, TYPES } from '@constants'

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid'
import AcButton from '@atoms/ac-button/ac-button.web'
import AcSpeechBubble from '@atoms/ac-speech-bubble/ac-speech-bubble.web'
import AcAvatar from '../../atoms/ac-avatar/ac-avatar.web'

const _CLASSES = {
  MAIN: 'ac-email-modal',
  CONTENT: 'ac-email-modal__content',
}

const AcEmailModalConfirm = ({
  store: { ui, tenant },
  label,
  cancel = {
    label: 'Cancel',
    callback: () => {},
  },

  speechBubbleContent,
  confirm = {
    label: 'Email',
    callback: () => {},
  },
  children,
}) => {
  const [bubbleContent, setBubbleContent] = useState(speechBubbleContent || '')

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault()
    if (cancel && cancel.callback) await cancel.callback()
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
  }

  const getConfirmButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.PITCH,
      title: confirm.label || 'Save',
      callback: handleCancel,
    }
  }, [])

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT])
  }, [])

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN])
  }, [])

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <AcContainer fluid>
          <AcAvatar visual={tenant.tenant?.avatar} lazy={false} xsmall={true} />

          <AcSpeechBubble content={bubbleContent}></AcSpeechBubble>

          <AcRow className={`h-flex-v-align-center gap-20`}>
            {children && children}
            <AcColumn
              xxs={12}
              xs={12}
              sm={12}
              md={12}
              className={`h-margin-top-25 h-text--align-left h-flex-h-align-center'}`}
            >
              <AcColumn xxs={12} xs={12} sm={12} md={12} className={`h-margin-top-25 h-text--align-center`}>
                <AcButton {...getConfirmButtonOptions}>
                  <span>{label}</span>
                </AcButton>
              </AcColumn>
            </AcColumn>
          </AcRow>
        </AcContainer>
      </div>
    </div>
  )
}

export default withStore(observer(AcEmailModalConfirm))
