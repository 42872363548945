import React, { useCallback, useMemo, memo } from 'react'
import clsx from 'clsx'

// Imports => Constants
import { VISUALS } from '@constants'

// Imports => Atoms
import AcImage from '@atoms/ac-image/ac-image.web'

const _CLASSES = {
  MAIN: 'ac-avatar',
  SMALL: 'ac-avatar--small',
  XSMALL: 'ac-avatar--xsmall',
  RESIZE: 'ac-avatar--resize',
  IMAGE: {
    MAIN: 'ac-avatar__image',
    WRP: 'ac-avatar__image-wrp',
  },
}

const AcAvatar = ({
  visual,
  fallback = VISUALS.AVATAR,
  xsmall = false,
  small = false,
  lazy = true,
  resize = true,
  className,
}) => {
  const getImageClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.MAIN)
  }, [])

  const getImageWrpClassNames = useMemo(() => {
    return clsx(_CLASSES.IMAGE.WRP)
  }, [])

  const getMainClassNames = useMemo(() => {
    return clsx(_CLASSES.MAIN, small && _CLASSES.SMALL, xsmall && _CLASSES.XSMALL, resize && _CLASSES.RESIZE, className)
  }, [xsmall, small, resize, className])

  return (
    <div className={getMainClassNames}>
      <AcImage source={visual} className={getImageClassNames} wrpClassName={getImageWrpClassNames} lazy={lazy} />
    </div>
  )
}

export default memo(AcAvatar)
